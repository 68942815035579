<template>
    <section id="content">
        <div class="content-wrap py-0 overflow-visible">
            <div class="card mx-auto mw-md border-0 shadow rounded-xl card-seo-about mb-5 mb-lg-0">
                <div class="card-body p-5">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="heading-block border-bottom-0 mb-0">
                                <div class="badge badge-pill badge-default">Individual web development services</div>
                                <p>We offer our many years of experience and a wide range of the web development services using the opportunities offered by the modern web technologies.</p>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <img style="width: 65%" src="../../assets/demos/seo/images/services/experience.svg" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section m-0 border-top-0 skrollable skrollable-between">
                <div class="container-fluid center clearfix">

                    <div class="heading-block">
                        <h2>Secure and scalable web applications</h2>
                    </div>
                    <div class="container">
                        <div class="row col-mb-50 mb-0">
                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg  fbox-dark fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-screen i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Web Strategy and Consultation</h3>
                                        <p>The Web applications have become an integral part of business in the modern world. It is difficult to imagine the absence of a website in any area of activity, whether it is the brand, the provision of services, the provision of support to one’s own customers. Our team with many years of experience in developing websites offers its’ customers its’ expert guidance for developing scalable, secure and convenient web applications, sites for all types of businesses.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-eye i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Web UI/UX</h3>
                                        <p>Our team of designers has a vast experience in developing of all types of the web interfaces from the web portals, admin panels to the mobile client-oriented web applications. We guarantee the provision of your employees and the customers with an intuitive, understandable web interface to support your business goals.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-beaker i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Custom site development</h3>
                                        <p>Using the first-class interface technologies and the frameworks AngularJS, AJAX, HTML5, CSS3 etc., our team of the front-end developers will turn the smooth web projects into the flexible mobile web applications.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg fbox-outline fbox-dark fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-stack i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Architecture, Design and Consultancy</h3>
                                        <p>Our team includes the experienced architects who carry out a thorough analysis of each web project in order to implement scalable, flexible, client-oriented architectures.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
            <div class="section m-0 border-0 bg-color dark" style="padding: 80px 0;">
                <div class="container center clearfix">
                    <div class="row mt-4 col-mb-30">
                        <div class="col-sm-6 col-lg-6">
                            <div class="feature-box fbox-center fbox-lg  fbox-dark fbox-effect">

                                <div class="fbox-content">
                                    <h3>System integration</h3>
                                    <p style="color: white">The integration is an important part of creating the effective products, regardless of whether a new application is being developed or an outdated one is being improved. We provide a powerful software integration by implementing the advanced web development solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <div class="feature-box lb fbox-center fbox-lg  fbox-dark fbox-effect">

                                <div class="fbox-content">
                                    <h3>SaaS Products Development</h3>
                                    <p style="color: white">Serving all the types of activities from accounting to project management, the software as a service has changed the way we do business. A careful planning and the implementation with a subsequent integration improvements are required to implement a successful SaaS product. Our team of experts will be perfect for you to sell the products.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section m-0 border-top-0 skrollable skrollable-between">
                <div class="container-fluid center clearfix">

                    <div class="container">
                        <div class="row col-mb-50 mb-0">
                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-screen i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>User web portal development</h3>
                                        <p>To increase the sales, to improve the customer service, to optimize the content distribution and to improve the communication - we develop the specialized web portals such as: customer portals, employee portals, information portals, and so on.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg   fbox-dark fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-eye i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Development of the trading electronic platforms</h3>
                                        <p>We develop the user online platforms for sale to enable your business to demonstrate and monetize your products. We offer the flexible web solutions for the electronical commerce and development of your online customer base. Due to the development of the powerful mobile web applications, the functional backend administration panels, its’ simply use and a reliable payment gateway system.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg fbox-dark fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-beaker i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Modernization and Applications Updating</h3>
                                        <p>In order for your outdated application to work properly again and to bring profits it is not necessary to develop it from scratch. Our team of professionals will update it by introducing all the new technologies, while retaining of what is working, so that the time and money invested earlier are not in vain.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="feature-box fbox-center fbox-lg fbox-outline  fbox-effect">
                                    <div class="fbox-icon">
                                        <a href="#"><i class="icon-stack i-alt"></i></a>
                                    </div>
                                    <div class="fbox-content">
                                        <h3>Testing and Compliance with the requirements</h3>
                                        <p>To ensure the performance and the reliability of applications, we use a wide range of testing tools and infrastructures such as Selenium, XamarinTestCloud etc.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

            <ContactFragment />
        </div>
    </section>
</template>

<script>
    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Web",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Web',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        components: {
            ContactFragment,

        },
    }
</script>

<style scoped>
    .lb {
        border-left: 2px solid white;
        padding-right: 15px;
    }

</style>